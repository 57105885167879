<template>
  <main-layout>
    <v-container fluid>
      <v-row>
        <v-col
          xs="12"
          lg="10"
        >
          <h2 class="headline mb-3">Links</h2>
          <base-divider
            color="accent"
            dense
          />
          <ul class="link-list">
            <li>
              <a href="http://www.itg.be/"> Instituut voor Tropische Geneeskunde - www.itg.be </a>
            </li>
            <li>
              <a href="http://www.uzleuven.be/"> Universitair Ziekenhuis Leuven - www.uzleuven.be </a>
            </li>
            <li>
              <a href="http://www.huisarts.be/"> Huisarts van wacht - www.huisarts.be </a>
            </li>
            <li>
              <a href="http://www.tandarts.be/wachtdienst"> Tandarts van wacht - www.tandarts.be</a>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </main-layout>
</template>

<script>
  import MainLayout from '@/layouts/Main.vue'
  import BaseDivider from '@/components/Divider.vue'

  export default {
    head () {
      return {title: 'links'}
    },
    components: {
      MainLayout,
      BaseDivider
    }
  }
</script>
<style lang="scss" scoped>
  .link-list {
    list-style: none;
    padding: 0;
    > li {
      margin-bottom: 16px;
    }
  }
</style>
